// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-haftungsausschluss-js": () => import("./../../../src/pages/haftungsausschluss.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-migration-js": () => import("./../../../src/pages/migration.js" /* webpackChunkName: "component---src-pages-migration-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-posts-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/posts/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-posts-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

